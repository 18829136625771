import forEach from '../../../../javascripts/dom/forEach';

let eventTicketLists = document.querySelectorAll('[data-js-module="o-event-ticket-list"]');

const init = async () => {
  const { default: EventTicketList } = await import(
    /* webpackChunkName: "o-event-ticket-list" */ './event-ticket-list.jsx'
  );

  forEach(eventTicketLists, (index, element) => new EventTicketList(element));
};

const openEvent = document.querySelector('[data-js-module="o-event-ticket-list"] .a-button');
if (openEvent) {
  openEvent.addEventListener('click', event => {
    if (eventTicketLists.length) init();
  });
}

window.addEventListener('Manual.init', e => {
  eventTicketLists = document.querySelectorAll('[data-js-module="o-event-ticket-list"]');
  if (eventTicketLists.length) init();
});
