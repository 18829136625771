import forEach from '../../../../javascripts/dom/forEach';

const tabs = document.querySelectorAll('[data-js-module="o-tabs"]');

const init = async () => {
  const { default: Tabs } = await import(/* webpackChunkName: "m-tabs" */ './tabs');

  forEach(tabs, (index, element) => new Tabs(element));
};

if (tabs.length) init();
