import forEach from '../../../../javascripts/dom/forEach';

let accordions = document.querySelectorAll('[data-js-module="o-accordion"]');

const init = async () => {
  const { default: Accordion } = await import(/* webpackChunkName: "o-accordion" */ './accordion');

  forEach(accordions, (index, element) => new Accordion(element));
};

window.addEventListener('Manual.init', e => {
  accordions = document.querySelectorAll('[data-js-module="o-accordion"]');
  if (accordions.length) init();
});

if (accordions.length) init();
