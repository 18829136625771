import forEach from '../../../../javascripts/dom/forEach';

let searchAutos = document.querySelectorAll('[data-js-module="a-search-auto"]');

const init = async () => {
  const { default: SearchAuto } = await import(
    /* webpackChunkName: "a-search-auto" */ './search-auto.jsx'
  );

  forEach(searchAutos, (index, element) => {
    SearchAuto(element);
  });
};

window.addEventListener('Manual.init', e => {
  searchAutos = document.querySelectorAll('[data-js-module="a-search-auto"]');
  if (searchAutos.length) init();
});

if (searchAutos.length) init();
