import forEach from '../../../../javascripts/dom/forEach';

let selects = document.querySelectorAll('[data-js-module="a-select"]');

const init = async () => {
  const { default: Select } = await import(/* webpackChunkName: "a-select" */ './select.js');

  forEach(selects, (index, element) => new Select(element));
};

window.addEventListener('Manual.init', e => {
  selects = document.querySelectorAll('[data-js-module="a-select"]');
  if (selects.length) init();
});

if (selects.length) init();
