/* eslint-disable no-undef */
/**
 * Tracks events
 * @param {string} eventCategory Module the user interacted with
 * @param {string} eventAction Event action (e.g. submit, filter)
 * @param {string} eventLabel Additional information
 * @param {string} eventValue Additional information
 * @returns {void}
 */
export default (eventCategory, eventAction, eventLabel = null, eventValue = null) => {
  if (typeof dataLayer !== 'undefined') {
    dataLayer.push({
      event: 'user_interaction',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
      eventServices: 'GA'
    });
  }
};
