const lazyImages = document.querySelectorAll('img.lazyload');
const newsTeaserContainer = document.querySelectorAll('.o-teaser-container');
const image = document.createElement('img');
const srcSetSupport = 'srcset' in image && 'sizes' in image;

const initLazyload = async () => {
  await import(/* webpackChunkName: "a-image--lazysizes" */ 'lazysizes');
};

if (lazyImages.length || newsTeaserContainer.length) initLazyload();

const loadPicturefill = async () => {
  await import(/* webpackChunkName: "a-image-picturefill" */ 'picturefill');
};

if (!srcSetSupport || newsTeaserContainer.length) loadPicturefill();
