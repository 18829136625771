import forEach from '../../../../javascripts/dom/forEach';

let showMoreButtons = document.querySelectorAll('[data-js-module="a-show-more-button"]');

const init = async () => {
  const { default: ShowMoreButton } = await import(
    /* webpackChunkName: "a-show-more-button" */ './show-more-button'
  );

  forEach(showMoreButtons, (index, element) => new ShowMoreButton(element));
};

window.addEventListener('Manual.init', e => {
  showMoreButtons = document.querySelectorAll('[data-js-module="a-show-more-button"]');
  if (showMoreButtons.length) init();
});

if (showMoreButtons.length) init();
