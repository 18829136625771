const banner = document.querySelector('[data-js-module="m-banner"]');

const bannerId = banner ? banner.getAttribute('data-js-banner-id') : false;
const userBannerInteraction = bannerId ? localStorage.getItem(`banner-${bannerId}`) : false;

const init = async () => {
  const { default: Banner } = await import(/* webpackChunkName: "m-banner" */ './banner.js');

  new Banner(banner);
};

if (banner && !userBannerInteraction) init();
