import forEach from '../../../../javascripts/dom/forEach';

let faqSections = document.querySelectorAll('[data-js-module="o-faq-section"]');

const init = async () => {
  const { default: FaqSection } = await import(
    /* webpackChunkName: "o-faq-section" */ './faq-section.jsx'
  );

  forEach(faqSections, (index, element) => {
    FaqSection(element);
  });
};

window.addEventListener('Manual.init', e => {
  faqSections = document.querySelectorAll('[data-js-module="o-faq-section"]');
  if (faqSections.length) init();
});

if (faqSections.length) init();
