import inViewport from '../../../../javascripts/helpers/inViewport';
import trackEvent from '../../../../javascripts/helpers/trackEvent';
import forEach from '../../../../javascripts/dom/forEach';

const stageImage = document.querySelector('[data-js-module="o-stage-image"]');
const init = () => {
  const stageImageSearchLinks = stageImage.querySelectorAll('.a-faq-list-link__anchor');
  const stageImageSearchReadMoreLink = stageImage.querySelector('.m-faq-box__readmore');

  if (stageImageSearchLinks) {
    forEach(stageImageSearchLinks, (index, link) =>
      link.addEventListener('click', e =>
        trackEvent('Search_Stage_Question', 'ShowQuestion', e.target.href)
      )
    );
  }

  if (stageImageSearchReadMoreLink) {
    stageImageSearchReadMoreLink.addEventListener('click', e =>
      trackEvent('Search_Stage_All_Questions', 'ShowAllQuestions', e.target.href)
    );
  }

  inViewport(stageImage, 0.3);
};

if (stageImage) init();
