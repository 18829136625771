import forEach from '../../../../javascripts/dom/forEach';

let forms = document.querySelectorAll('[data-js-module="o-form"]');

const init = async () => {
  const { default: Form } = await import(/* webpackChunkName: "o-form" */ './form.js');

  forEach(forms, (index, element) => new Form(element));
};

window.addEventListener('Manual.init', e => {
  forms = document.querySelectorAll('[data-js-module="o-form"]');
  if (forms.length) init();
});

if (forms.length) init();
