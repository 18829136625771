const backToTopButton = document.querySelector('[data-js-module="a-back-to-top-button"]');

const BackToTopButton = element => {
  const button = element;

  window.addEventListener('scroll', () => {
    if (window.pageYOffset > 200) {
      button.style.opacity = 1;
    } else {
      button.style.opacity = 0;
    }
  });

  button.addEventListener('click', () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });
};

if (backToTopButton) BackToTopButton(backToTopButton);
