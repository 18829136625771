import forEach from '../../../../javascripts/dom/forEach';

let teaserImages = document.querySelectorAll('[data-js-module="a-teaser-image"]');

const init = async () => {
  const { default: TeaserImage } = await import(
    /* webpackChunkName: "a-teaser-image" */ './teaser-image.js'
  );

  forEach(teaserImages, (index, element) => TeaserImage(element));
};

window.addEventListener('Manual.init', e => {
  teaserImages = document.querySelectorAll('[data-js-module="a-teaser-image"]');
  if (teaserImages.length) init();
});

if (teaserImages.length) init();
