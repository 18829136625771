import forEach from '../../../../javascripts/dom/forEach';

let teaserContainers = document.querySelectorAll('[data-js-module="o-teaser-container"]');

const init = async () => {
  const { default: TeaserContainer } = await import(
    /* webpackChunkName: "o-teaser-container" */ './teaser-container.jsx'
  );

  forEach(teaserContainers, (index, element) => {
    TeaserContainer(element);
  });
};

window.addEventListener('Manual.init', e => {
  teaserContainers = document.querySelectorAll('[data-js-module="o-teaser-container"]');
  if (teaserContainers.length) init();
});

if (teaserContainers.length) init();
