import forEach from '../../../../javascripts/dom/forEach';

let downloads = document.querySelectorAll('[data-js-module="m-download"]');

const init = async () => {
  const { default: Download } = await import(/* webpackChunkName: "m-download" */ './download.js');

  forEach(downloads, (index, element) => Download(element));
};

window.addEventListener('Manual.init', e => {
  downloads = document.querySelectorAll('[data-js-module="m-download"]');
  if (downloads.length) init();
});

if (downloads.length) init();
