import forEach from '../../../../javascripts/dom/forEach';

let modals = document.querySelectorAll('[data-js-module="o-modal"]');

const init = async () => {
  const { default: Modal } = await import(/* webpackChunkName: "o-modal" */ './modal');

  forEach(modals, (index, element) => new Modal(element));
};

window.addEventListener('Manual.init', e => {
  modals = document.querySelectorAll('[data-js-module="o-modal"]');
  if (modals.length) init();
});

if (modals.length) init();
