import forEach from '../../../../javascripts/dom/forEach';

let personContacts = document.querySelectorAll('[data-js-module="o-person-contact"]');

const init = async () => {
  const { default: Personcontact } = await import(
    /* webpackChunkName: "o-person-contact" */ './person-contact'
  );

  forEach(personContacts, (index, element) => Personcontact(element));
};

window.addEventListener('Manual.init', e => {
  personContacts = document.querySelectorAll('[data-js-module="o-person-contact"]');
  if (personContacts.length) init();
});

if (personContacts.length) init();
