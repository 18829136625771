import forEach from '../../../../javascripts/dom/forEach';

let fileUploads = document.querySelectorAll('[data-js-module="m-file-upload"]');

const init = async () => {
  const { default: FileUpload } = await import(
    /* webpackChunkName: "m-file-upload" */ './file-upload.js'
  );

  forEach(fileUploads, (index, element) => new FileUpload(element));
};

window.addEventListener('Manual.init', e => {
  fileUploads = document.querySelectorAll('[data-js-module="m-file-upload"]');
  if (fileUploads.length) init();
});

if (fileUploads.length) init();
