import forEach from '../../../../javascripts/dom/forEach';

let multiStepForms = document.querySelectorAll('[data-js-module="o-multi-step-form"]');

const init = async () => {
  const { default: MultiStepForm } = await import(
    /* webpackChunkName: "o-multi-step-form" */ './multi-step-form.js'
  );

  forEach(multiStepForms, (index, element) => new MultiStepForm(element));
};

window.addEventListener('Manual.init', e => {
  multiStepForms = document.querySelectorAll('[data-js-module="o-multi-step-form"]');
  if (multiStepForms.length) init();
});

if (multiStepForms.length) init();
