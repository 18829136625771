let recaptchas = document.querySelectorAll('[data-js-module="a-recaptcha"]');

const resetAllRecaptchas = () => {
  // remove recaptcha script for filterd forms
  if (window.recaptcha) {
    // eslint-disable-next-line no-underscore-dangle
    delete window.___grecaptcha_cfg.clients;
    delete window.grecaptcha;
    delete window.recaptcha;
    const recaptchaScript = document.getElementById('recaptchaScript');
    document.body.removeChild(recaptchaScript);
  }
};

const init = () => {
  if (typeof window.recaptcha === 'undefined') {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.id = 'recaptchaScript';
    recaptchaScript.async = true;
    recaptchaScript.src = 'https://www.google.com/recaptcha/api.js';
    document.body.appendChild(recaptchaScript);
  }
};

window.addEventListener('Manual.init', e => {
  recaptchas = document.querySelectorAll('[data-js-module="a-recaptcha"]');
  if (recaptchas.length) {
    resetAllRecaptchas();
    init();
  }
});

if (recaptchas.length) init();
