import forEach from '../../../../javascripts/dom/forEach';

let videos = document.querySelectorAll('[data-js-module="a-video"]');

const init = async () => {
  const { default: Video } = await import(/* webpackChunkName: "a-video" */ './video');

  forEach(videos, (index, element) => new Video(element));
};

window.addEventListener('Manual.init', e => {
  videos = document.querySelectorAll('[data-js-module="a-video"]');
  if (videos.length) init();
});

if (videos.length) init();
