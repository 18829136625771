import forEach from '../../../../javascripts/dom/forEach';

const beforeAfterSliders = document.querySelectorAll('[data-js-module="o-before-after-slider"]');

const init = async () => {
  const { default: BeforeAfterSlider } = await import(
    /* webpackChunkName: "o-before-after-slider" */ './before-after-slider.jsx'
  );

  forEach(beforeAfterSliders, (index, element) => BeforeAfterSlider(element));
};

if (beforeAfterSliders.length) init();
