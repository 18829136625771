/**
 * Makes use of the observer API to check when an element
 * enters the viewport and applys a class
 * @param {element} element Element to check
 * @param {number} threshold Determines how much of the element has to be visibile
 * @returns {void}
 */
export default (element, threshold) => {
  const inViewportClass = 'is-in-viewport';
  let observer = null;

  const intersectionObserverOptions = {
    threshold
  };

  const animate = entries => {
    if (!element.classList.contains(inViewportClass) && entries[0].isIntersecting) {
      element.classList.add(inViewportClass);
      observer.unobserve(element);
    }
  };

  observer = new IntersectionObserver(animate, intersectionObserverOptions);
  observer.observe(element);
};
