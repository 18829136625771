import forEach from '../../../../javascripts/dom/forEach';

const contentFilters = document.querySelectorAll('[data-js-module="o-content-filter"]');

const init = async () => {
  const { default: ContentFilter } = await import(
    /* webpackChunkName: "o-content-filter" */ './content-filter.js'
  );

  forEach(contentFilters, (index, element) => {
    ContentFilter(element);
  });
};

if (contentFilters.length) init();
