import forEach from '../../../../javascripts/dom/forEach';
import trackEvent from '../../../../javascripts/helpers/trackEvent';

const relatedTopicsSection = document.querySelector('[data-js-module="m-related-topics"]');

const init = element => {
  const relatedTopics = element.querySelectorAll('.m-related-topics__link');

  if (relatedTopics) {
    forEach(relatedTopics, (i, relatedTopic) =>
      relatedTopic.addEventListener('click', () =>
        trackEvent('FAQ_Detailpage', 'Topic', relatedTopic.getAttribute('title'))
      )
    );
  }
};

if (relatedTopicsSection) init(relatedTopicsSection);
