import forEach from '../../../../javascripts/dom/forEach';

const lightboxConfigs = document.querySelectorAll('[data-js-lightbox-config]');

if (lightboxConfigs.length) {
  require.ensure(
    [],
    require => {
      const PhotoSwipe = require('photoswipe');
      const PhotoSwipeUiDefault = require('photoswipe/dist/photoswipe-ui-default');
      const Lightbox = require('./lightbox').default;

      forEach(lightboxConfigs, (_, element) => {
        new Lightbox(element, PhotoSwipe, PhotoSwipeUiDefault);
      });
    },
    'm-lightbox'
  );
}
