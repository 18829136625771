import forEach from '../../../../javascripts/dom/forEach';

let eventForms = document.querySelectorAll('[data-js-module="o-event-form"]');

const init = async () => {
  const { default: EventForm } = await import(
    /* webpackChunkName: "o-event-form" */ './event-form.js'
  );

  forEach(eventForms, (index, element) => new EventForm(element));
};

window.addEventListener('Manual.init', e => {
  eventForms = document.querySelectorAll('[data-js-module="o-event-form"]');
  if (eventForms.length) init();
});

if (eventForms.length) init();
