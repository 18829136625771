import trackEvent from '../../../../javascripts/helpers/trackEvent';

const filterNavigations = document.querySelectorAll('[data-js-module="m-filter-navigation"]');

const init = element => {
  const filterTag = element.querySelectorAll('[data-js-element="m-filter-navigation__filter-tag"]');

  filterTag.forEach(filter =>
    filter.addEventListener('click', () =>
      trackEvent('Search_Result_Page', 'Filter', filter.getAttribute('title'))
    )
  );
};

if (filterNavigations.length) filterNavigations.forEach(filterNavigation => init(filterNavigation));
