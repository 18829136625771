import forEach from '../../../../javascripts/dom/forEach';

let teaserContainerCategories = document.querySelectorAll(
  '[data-js-module="o-teaser-container-categories"]'
);

const init = async () => {
  const { default: TeaserContainerCategories } = await import(
    /* webpackChunkName: "o-teaser-container-categories" */ './teaser-container-categories.jsx'
  );

  forEach(teaserContainerCategories, (index, element) => {
    TeaserContainerCategories(element);
  });
};

window.addEventListener('Manual.init', e => {
  teaserContainerCategories = document.querySelectorAll(
    '[data-js-module="o-teaser-container-categories"]'
  );

  if (teaserContainerCategories.length) init();
});

if (teaserContainerCategories.length) init();
